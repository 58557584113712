import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const DiagnosticContentSuccessCaseStudies2023Guide = ({
    location
  }) => {
  const title =
    "【9-11月】秋のキャンペーン企画アイデア｜参考になる！具体例付き"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【目的別】診断コンテンツの成功事例まとめ！活用のポイントを紹介｜保存版"
          pagedesc="診断コンテンツとは、企業側が用意した設問にユーザーが回答し、その診断結果をその場ですぐに表示できるコンテンツのこと。本記事では「ブランドの認知拡大」「販売促進」「ユーザーデータの取得」など各企業の目的別に、具体的な活用事例を紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230726/230726-1.png"
          pagepath="/blog/diagnostic-content-success-case-studies-2023-guide"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              【目的別】診断コンテンツの成功事例まとめ！活用のポイントを紹介｜保存版
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">
              更新日：2024年1月25日　公開日：2023年7月26日
            </time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="スマートフォンを見て笑っている女性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230726/230726-1.png"
                className="w-full"
              />
            </figure>
            <h2 id="id1">診断コンテンツは何ができるの？</h2>
            <p>
              自社の商品やサービスをHPやSNSで宣伝しているものの、なかなかユーザーに興味を持ってもらえない…。そんなときに有効活用できるのが「診断コンテンツ」です。
            </p>
            <p>
              診断コンテンツとは、企業側が用意した設問にユーザーが回答し、その診断結果をその場ですぐに表示できるコンテンツのこと。誰でもすぐにWeb上で答えられるためユーザーの参加率が高く、「ブランドの認知拡大」「商品の販売促進」「ユーザーデータの取得」など、さまざまなメリットがあります。
            </p>
            <p>
              ただ、コンテンツの内容やデザインが自由ではあるものの、その分どのようなコンテンツを用意すればいいのか、イメージが湧かない方も多いのではないでしょうか。
            </p>

            <h2 id="id2">自社で参考にできる成功事例をまとめました</h2>
            <p>
              そこで本記事では、目的別に診断コンテンツの具体的な事例を紹介します。成果を出すためのコンテンツ設計についても解説するので、ぜひご活用ください。
            </p>

            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>
                診断コンテンツを作成したいけど、自社に合ったコンテンツ内容がわからない方
              </li>
              <li>モチベーションの高い見込み客をキャンペーンで集めたい方</li>
              <li>
                フォロー＆リツイートキャンペーン以外の販促キャンペーンをリサーチしている担当者
              </li>
            </ul>
            <p>
              ※
              本記事は、マーケティング担当者へ向けて他社様の事例を集めたアイデア用記事となります。紹介する診断コンテンツは、弊社の制作物ではありません。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    診断コンテンツは何ができるの？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    自社で参考にできる成功事例をまとめました
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. 診断コンテンツとは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        診断コンテンツは参加のハードルが低い
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        診断コンテンツのメリット
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        診断コンテンツの注意点
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    2. 診断コンテンツ成功事例①「ブランドの認知拡大」
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        「ポケモン自己分析」（株式会社ポケモンセンター／リクルートサイト）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        「泳げ!人生という大海を
                        誕生魚診断®2.0」（マルハニチロ株式会社）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        「インディーゲーム診断」（株式会社BlackBeardDesignStudio）
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    3. 診断コンテンツ成功事例②「リード獲得〜ナーチャリング」
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        「あなたにピッタリの旅行プラン診断」（株式会社日本旅行）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        「⼆世帯タイプ診断」(株式会社 Lakke)
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        「ITエンジニア・クリエイターのための年収診断」（レバテック株式会社）
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    4. 診断コンテンツ成功事例③「商品の販売促進」
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        「インターネットプラン診断」（ソフトバンク株式会社）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        「からだかがみ」（クラシエホールディングス）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id18" className="toc__link">
                        「LEC資格占い」（株式会社東京リーガルマインド）
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id19" className="toc__link">
                    5. 診断コンテンツ成功事例④「ユーザーデータの取得」
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id20" className="toc__link">
                        「ビア充診断」（サッポロビール株式会社）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id21" className="toc__link">
                        「ビジネスタイプ診断」（Sansan株式会社）
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id22" className="toc__link">
                    6.
                    まとめ：自社にぴったりの診断コンテンツでマーケ効果をアップ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. 診断コンテンツとは？</h2>

              <h3 id="id4">診断コンテンツは参加のハードルが低い</h3>
              <p>
                診断コンテンツは、性格診断、キャラクター診断、○○検定、おすすめメニュー診断など、エンタメ性の高い内容を自由にカスタマイズできます。ユーザーの興味関心に沿ったコンテンツ内容を設定できるので、参加率が自然と高くなります。
              </p>
              <p>
                また、企業側からのPRではなく「自分のことを知りたい」など人間の潜在的な欲求にアプローチするので、自ら進んで参加するユーザーが増えていきます。
              </p>

              <h3 id="id5">診断コンテンツのメリット</h3>
              <ol>
                <li>
                  <b>「興味喚起に優れている」</b>
                  <p>
                    「自分のことを知りたい」「診断してほしい」というユーザーの気持ちをフックに、自社のコンテンツに対して興味を持ってもらえます。
                  </p>
                </li>
                <li>
                  <b>PVの向上やWebサイトへのトラフィックの増加</b>
                  <p>
                    診断コンテンツがシェアされることで、より多くのユーザーの目に留まる確率が高まります。その結果、PV数やトラフィックの増加も期待できます。
                  </p>
                </li>
                <li>
                  <b>商品レコメンドが可能</b>
                  <p>
                    自社の商品やサービスに関連する診断コンテンツが作成できるので、ユーザーに対して診断結果に合った商品をおすすめできます。
                  </p>
                </li>
                <li>
                  <b>顧客獲得・認知拡大・ブランディングに効果的</b>
                  <p>
                    診断コンテンツに診断結果をシェアできる機能をつけることで、SNSキャンペーンと連動させながら効果的な情報拡散が可能になります。
                  </p>
                </li>
                <li>
                  <b>ユーザーのデータ収集も可能</b>
                  <p>
                    診断コンテンツを利用すれば、ユーザーデータを低コストで効率的に集められます。SNSで拡散されれば、短期間に多くのデータを収集できるでしょう。
                  </p>
                </li>
              </ol>
              <p>
                診断コンテンツのメリットについては、以下の記事でも詳しく解説しています。こちらもぜひご覧ください。
              </p>

              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【初心者向け】診断コンテンツの作り方とキャンペーンで伸ばすコツとは？
                  </a>
                </p>
              </div>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="診断コンテンツの手順"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230726/230726-2.png"
              />

              <h3 id="id6">診断コンテンツの注意点</h3>
              <p>
                診断コンテンツはメリットが多いものの、その特性を活かしきれないと効果が十分に発揮されません。以下の3つの注意点について、頭に入れておきましょう。
              </p>

              <p>
                <b>注意点① 時間やリソースをかけすぎる</b>
              </p>
              <p>
                デザインや診断内容に凝りすぎてしまうと、リソースがかかっていつまでたってもコンテンツが完成しません。例えば、あらかじめ用意されたフォーマットを活用すれば、リソースを抑えることもできます。
              </p>
              <p>
                <b>注意点② 不必要な設問を用意してしまう</b>
              </p>
              <p>
                設問を増やせば、それだけデータも集まるので運営側にとってはメリットが多いでしょう。しかし、ユーザー側にとっては負担が増えるので、想定以上のユーザーが離脱するリスクも考えられます。必要な設問のみを用意するようにしましょう。
              </p>
              <p>
                <b>注意点③ 診断コンテンツを作成して満足してしまう</b>
              </p>
              <p>
                診断コンテンツは、ユーザーの回答後に購買やプロモーション、拡散などにつながるアプローチを行うことが重要です。診断コンテンツの実施からアプローチまで、一連の流れを設計するようにしましょう。
              </p>
            </div>
            <div>
              <h2 id="id7">2. 診断コンテンツ成功事例①「ブランドの認知拡大」</h2>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="一緒にスマートフォンを見ている3人の女性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230726/230726-3.png"
              />
              <p>※紹介する診断コンテンツは弊社の制作物ではありません。</p>

              <h3 id="id8">
                <a
                  href="https://recruit.pokemon.co.jp/pokemoncenter/special/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「ポケモン自己分析」
                </a>
                （株式会社ポケモンセンター／リクルートサイト）
              </h3>
              <p>
                ポケモンのカードゲームや、グッズ販売などを手がける株式会社ポケモンセンターが提供。同社のリクルートサイトに設置されており、人材採用の目的で活用されています。
              </p>
              <p>
                合計16個の質問に回答することで、ユーザーの性格を診断。結果の画面では「キミをポケモンに例えると」というように、本作品の人気キャラクターに例えて分析しており、他の特徴を持つ人との付き合い方・相性もわかります。ページの最後にはSNSにシェアするリンクや、同社の採用サイトへのリンクも用意されています。
              </p>
              <p>
                ポケモンはゲーム・アニメの印象が強いですが、本診断コンテンツで作品自体の影響力を活かしつつ、「株式会社ポケモンセンター」の認知拡大につなげています。
              </p>

              <h3 id="id9">
                <a
                  href="https://www.maruha-nichiro.co.jp/birthfish/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「泳げ!人生という大海を 誕生魚診断®2.0」
                </a>
                （マルハニチロ株式会社）
              </h3>
              <p>
                さば、さんま、かになどの水産加工品で有名なマルハニチロ株式会社が提供。自分や相手の生年月日を入力するだけで、「おさかなエレメント」（魚のタイプ）と「誕生魚」を診断し、ユーザーの性格、特徴、相性などがわかります。
              </p>
              <p>
                診断コンテンツの公開と同時に、「人生という大海において一つの大きな節目を迎える」というテーマで新成人応援のプレゼントキャンペーンも開催。同社のTwitter公式アカウントでフォロー＆リツイートキャンペーンも開催し、マルハニチロの商品をプレゼントするなど、新たなファン獲得を進めています。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </p>
              </div>

              <h3 id="id10">
                <a
                  href="https://www.kurohige.jp/indie/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「インディーゲーム診断」
                </a>
                （株式会社BlackBeardDesignStudio）
              </h3>
              <p>
                「あつまれ
                どうぶつの森」「パズドラGOLD」などの人気ゲームで、デザイン・アニメーション等の制作協力をしている株式会社BlackBeardDesignStudioが提供。4択で出題される16問に答えると、性格タイプと「性格にあったインディーゲーム」が分かります。
              </p>
              <p>
                「インディーゲーム」とは、少人数・低予算で開発されたゲームのこと。本診断コンテンツでは、自社のゲームだけではなく、他社のゲームも紹介することで業界全体を盛り上げ、「インディーゲーム」そのものの認知度を高めようとする工夫が見られます。
              </p>
            </div>
            <div>
              <h2 id="id11">
                3. 診断コンテンツ成功事例②「リード獲得〜ナーチャリング」
              </h2>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="診断コンテンツ成功事例②「リード獲得〜ナーチャリング」"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230726/230726-4.png"
              />
              <p>※紹介する診断コンテンツは弊社の制作物ではありません。</p>

              <h3 id="id12">
                <a
                  href="https://www.nta.co.jp/summer/shindan/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「あなたにピッタリの旅行プラン診断」
                </a>
                （株式会社日本旅行）
              </h3>
              <p>
                株式会社日本旅行が提供している「あなたにピッタリの旅行プラン診断」。2択で構成された合計10問の質問に答えることで、ユーザーにぴったりの旅行プランを導き出します。
              </p>
              <p>
                特に効果を発揮するのは「旅行に行くならどこがいいんだろう」と迷っているユーザーに対してです。相性の良い旅行を提案することにより、ニーズを顕在化させることが可能。さらに、診断結果をSNSでシェアしてもらうことにより、他の旅行好きユーザーにも訴求できます。
              </p>
              <p>
                診断結果の画面には旅先の写真を掲載するなど、旅行へのモチベーションが自然と高まるような工夫も。新規リードを獲得するだけではなく、その後の「受注」にも貢献する診断コンテンツだといえるでしょう。
              </p>

              <h3 id="id13">
                <a
                  href="https://www.lakke.co.jp/iroha/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「⼆世帯タイプ診断」
                </a>
                （株式会社 Lakke）
              </h3>
              <p>
                さまざまな同居スタイルの家づくりを支援している株式会社
                Lakkeが提供。二世帯住宅のイロハを伝える「⼆世帯タイプ診断」では、10問ほどの質問に答えることで、自分にぴったりの間取りや生活スタイルなどがわかります。
              </p>
              <p>
                質問の選択肢には、補足説明としてイラストが添えられており、家族構成など複雑な情報も簡単に答えられます。
              </p>
              <p>
                さらに、診断結果ページでは生活スタイルを動物に例えて解説。SNSで拡散したくなるのはもちろん、同居予定の家族とのコミュニケーションにもつながります。診断結果の最後には「個別相談会」「オンライン個別相談会」などのリンクが設置してあり、新規リードの獲得に貢献するコンテンツとなっています。
              </p>

              <h3 id="id14">
                <a
                  href="https://career.levtech.jp/diagnosis/income/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「ITエンジニア・クリエイターのための年収診断」
                </a>
                （レバテック株式会社）
              </h3>
              <p>
                他者の統計データと比較することにより効果的にリード獲得しているのが、レバテック株式会社が提供する「ITエンジニア・クリエイターのための年収診断」です。年収・職種・年齢・経験年数という4つの質問に答えることで、自分と同じ職種・年代と比較した自身の年収相場が分かります。
              </p>
              <p>
                あらゆる人に関わりのある「年収」をテーマにすることにより、今すぐ転職しようとしているユーザーだけでなく、中長期的に転職を考えている人の興味を引くことが可能に。ニーズの低いユーザーに対して「年収を高めるためには」という情報を発信することにより、新たなニーズを喚起することができます。
              </p>
              <p>
                診断結果ページでは、自身が入力した現在の年収以上の「求人特集」が案内されています。もう一つのリンク（キャリアをアドバイザーに相談する）よりもハードルが低く、どんな求人があるのか見てみたくなります。自分に合った求人情報を見ることにより、転職への興味関心が高まる設計になっています。
              </p>
            </div>
            <div>
              <h2 id="id15">4. 診断コンテンツ成功事例③「商品の販売促進」</h2>
              <p>※紹介する診断コンテンツは弊社の制作物ではありません。</p>
              <h3 id="id16">
                <a
                  href="https://www.softbank.jp/internet/special/check-plan/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「インターネットプラン診断」
                </a>
                （ソフトバンク株式会社）
              </h3>
              <p>
                3つの簡単な質問に答えるだけで、ユーザーにとってピッタリなプランがわかる「インターネットプラン診断」。具体的なプランを提案することにより、自社のインターネット回線サービスの申し込みに誘導しています。
              </p>
              <p>
                とはいえ、診断結果で最適なプランが分かったとしても、人は具体的なメリットがないと行動しません。そのような心理的ハードルをクリアするために、診断結果のページでは「全額キャッシュバック」などセット申し込みで割引になるプランも提案しています。
              </p>
              <p>
                プランの具体的な特徴・料金についても案内しているので、商品の販売促進を目的にした診断コンテンツだといえるでしょう。
              </p>

              <h3 id="id17">
                <a
                  href="https://www.kracie.co.jp/ph/k-therapy/karadakagami/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「からだかがみ」
                </a>
                （クラシエホールディングス）
              </h3>
              <p>
                医薬品・日用品・食品などの事業を展開しているクラシエホールディングス株式会社が提供。同社には漢方薬ブランド「漢方セラピー」があるため、漢方をもっと身近にすることを目指して診断コンテンツを設計しています。
              </p>
              <p>
                質問の中では「疲れやすい」「イライラしやすい」など、日々の生活で何となく感じる「体の不調」についてのストレスを明確にしていきます。診断結果では、ユーザーが放置しがちな「長年の体の悩み」と「解決策」を明示。ユーザーの体質を分類し、タイプ別のよくある症状を解説しています。
              </p>
              <p>
                ユーザーは思い当たる症状をクリックすることで、おすすめの漢方薬を知ることができます。さらに、「漢方セラピー」の商品ページには販売店を案内するマップも。同社において漢方薬は基本的にネット販売されていないため、実際に購入できる場所に誘導しているのです。診断コンテンツで商品販売する際には、ECサイトに誘導することが多いですが、本事例のように実店舗に誘導することもできます。
              </p>

              <h3 id="id18">
                <a
                  href="https://www.lec-jp.com/license_guide/uranai/mb/index.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  「LEC資格占い」
                </a>
                （株式会社東京リーガルマインド）
              </h3>
              <p>
                全国各地に拠点を持つ資格の総合スクール「LEC東京リーガルマインド」（運営：株式会社東京リーガルマインド）が提供。資格試験が多様化する中、ユーザーが自分に合った資格を楽しみながら見つけられることを目的に制作されています。
              </p>
              <p>
                20の簡単な質問に答えるだけで、自分の長所・短所、性格にぴったりの資格がわかります。結果の画面には、愛嬌のある侍のキャラクターイラストを表示。ユーザーの長所と短所に合わせて、おすすめの資格を案内しています。
              </p>
              <p>
                ページの最後には、無料で始められる資格取得のバナーも設置。自分の長所や短所などの診断結果を見た後で、そのままサービスサイトに誘導するような設計になっています。
              </p>
            </div>
            <div>
              <h2 id="id19">
                5. 診断コンテンツ成功事例④「ユーザーデータの取得」
              </h2>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="診断コンテンツ成功事例④「ユーザーデータの取得」"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230726/230726-5.png"
              />
              <p>※紹介する診断コンテンツは弊社の制作物ではありません。</p>
              <h3 id="id20">
                <a
                  href="https://www.sapporobeer.jp/beerjushindan/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「ビア充診断」
                </a>
                （サッポロビール株式会社）
              </h3>
              <p>
                自分に合ったビールの楽しみ方がわかる診断コンテンツ。ユーザーへの全19問の質問により、詳細なアンケートデータを取得しています。
              </p>
              <p>
                質問項目は「ビールは仲間と飲んで、乾杯したい！」などわかりやすい内容で、回答は選択式になっています。「あてはまる／ややあてはまる／どちらともいえない／あまりあてはまらない／あてはまらない」の5択なので、2〜3分あれば答えられます。
              </p>
              <p>
                診断結果には、「アンケートに答えて相性のいいビア充を見る。」というコンテンツを用意。他のキャラクターのイラストを見せて、会員登録へ誘導しています。また、診断コンテンツの公開時には、Twitterでプレゼントキャンペーンも開催していました。SNSと連動させることで、より多くのユーザーデータを集められます。
              </p>

              <h3 id="id21">
                <a
                  href="https://materials.8card.net/product/tenth-anniversary/business-type/"
                  target="_blank"
                  rel="noreferrer"
                >
                  「ビジネスタイプ診断」
                </a>
                （Sansan株式会社）
              </h3>
              <p>
                名刺管理やキャリア形成に活用できるキャリアプロフィール「Eight」を運営しているSansan株式会社が提供。「ビジネスタイプ診断」は、ユーザーのキャリア形成に役立つ診断コンテンツとして設計されています。
              </p>
              <p>
                質問内容は全40問で、「プレイヤーになるよりも、管理職になるほうが魅力的だ」など、キャリアに関する具体的な質問を設定。4択で直感的に答えられるので、ユーザーの離脱は起きにくいのではないでしょうか。多くのユーザーから詳細なデータを集められるように作られています。
              </p>
              <p>
                診断結果では、キャリアのタイプ、傾向・価値観、最適な将来像などを詳しく解説。また、ページ後半には「転職意向度を設定する」というCTAを設置しており、回答後のユーザーデータを収集することにも役立っています。
              </p>
            </div>
            <div>
              <h2 id="id22">
                6. まとめ：自社にぴったりの診断コンテンツでマーケ効果をアップ
              </h2>
              <p>
                今回は診断コンテンツの成功事例を紹介しました。企業やブランドの特徴や、キャンペーンの目的によって、最適なコンテンツ内容が異なります。
              </p>
              <p>
                「自社のユーザーにとってはどんな診断が合っているのだろう？」と気になっている方は、今回の記事を参考に診断コンテンツを実施してみてください。
              </p>
              <p>
                ただ、実際に作成しようとすると、どのように診断コンテンツを作成すればいいのか分からないこともあるでしょう。その場合は、診断コンテンツを制作できる専門会社に相談するのも一つの手です。
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="PARKLoTの診断コンテンツ作成の流れ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230726/230726-6.png"
              />
              <p>
                PARKLoTでは、拡散されやすい診断コンテンツの企画・設計のサポートが可能。診断コンテンツと併用すると効果の高いTwitterキャンペーンも合わせて依頼できます。
              </p>
              <p>
                完全カスタマイズのオリジナル診断コンテンツはもちろん、フォーマットを活用して制作コストを最小減にすることもできます。
              </p>
              <p>
                お急ぎのときにもPARKLoTなら診断コンテンツの実装まで最短1ヶ月なので、お気軽にお問い合わせください。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【初心者向け】診断コンテンツの作り方とキャンペーンで伸ばすコツとは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default DiagnosticContentSuccessCaseStudies2023Guide
